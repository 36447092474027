<script lang="tsx">
import type { PropType, SlotsType } from 'vue'
import { NuxtImg } from '#components'
import type { ComponentOverrideOptions } from '../../../types/components'

export type CoreImgProps<T> = {
    src: string | null | undefined
    fallbackSrc?: string
    alt: string | null | undefined
    format?: string
    quality?: number | string
    preset?: string | null
    sizes?: string
    preload?: boolean
    width?: string | number
    height?: string | number
    fit?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside'
    densities?: string
    loading?: 'lazy' | 'eager'
    placeholder?: number[]
}

type CoreImgSlots<T> = {}

type ComponentOptions = {}

export function defineComponentCoreImg<T>(options?: ComponentOverrideOptions<ComponentOptions, CoreImgProps<T>, CoreImgSlots<T>>) {
    return defineComponent(
        (props: CoreImgProps<T>, ctx) => {

            const imageSrc = ref<string | null>(props.src || null)

            watch(() => props.src, (newSrc) => {
                imageSrc.value = newSrc || null
            })

            const isError = ref<boolean>(false)

            function handleImageLoadError() {
                isError.value = true
            }

            return () => imageSrc.value && !isError.value
                ? (
                    <NuxtImg
                        src={imageSrc.value}
                        alt={props.alt ?? ''}
                        format={props.format}
                        fit={props.fit}
                        quality={props.quality}
                        preset={props.preset !== null ? props.preset ?? 'default' : undefined}
                        sizes={props.sizes}
                        preload={props.preload}
                        width={props.width}
                        height={props.height}
                        densities={props.densities}
                        loading={props.loading}
                        placeholder={props.placeholder}
                        onError={handleImageLoadError}
                    />
                )
                : props.fallbackSrc
                    ? (
                        <img
                            class="!size-full !object-contain"
                            src={props.fallbackSrc}
                            alt={props.alt ?? ''}
                            onError={handleImageLoadError}
                        />
                    )
                    : null
        },
        {
            props: {
                src: {
                    type: String as PropType<CoreImgProps<T>['src']>,
                    required: options?.props?.src?.required ?? true,
                    default: options?.props?.src?.default ?? undefined,
                },
                fallbackSrc: {
                    type: String as PropType<CoreImgProps<T>['fallbackSrc']>,
                    required: options?.props?.fallbackSrc?.required ?? false,
                    default: options?.props?.fallbackSrc?.default ?? undefined,
                },
                alt: {
                    type: String as PropType<CoreImgProps<T>['alt']>,
                    required: options?.props?.alt?.required ?? true,
                    default: options?.props?.alt?.default ?? undefined,
                },
                format: {
                    type: String as PropType<CoreImgProps<T>['format']>,
                    required: options?.props?.format?.required ?? false,
                    default: options?.props?.format?.default ?? undefined,
                },
                quality: {
                    type: [Number, String] as PropType<CoreImgProps<T>['quality']>,
                    required: options?.props?.quality?.required ?? false,
                    default: options?.props?.quality?.default ?? undefined,
                },
                preset: {
                    type: String as PropType<CoreImgProps<T>['preset']>,
                    required: options?.props?.preset?.required ?? false,
                    default: options?.props?.preset?.default ?? undefined,
                },
                sizes: {
                    type: String as PropType<CoreImgProps<T>['sizes']>,
                    required: options?.props?.sizes?.required ?? false,
                    default: options?.props?.sizes?.default ?? undefined,
                },
                preload: {
                    type: Boolean as PropType<CoreImgProps<T>['preload']>,
                    required: options?.props?.preload?.required ?? false,
                    default: options?.props?.preload?.default ?? false,
                },
                width: {
                    type: [String, Number] as PropType<CoreImgProps<T>['width']>,
                    required: options?.props?.width?.required ?? false,
                    default: options?.props?.width?.default ?? undefined,
                },
                height: {
                    type: [String, Number] as PropType<CoreImgProps<T>['height']>,
                    required: options?.props?.height?.required ?? false,
                    default: options?.props?.height?.default ?? undefined,
                },
                fit: {
                    type: String as PropType<CoreImgProps<T>['fit']>,
                    required: options?.props?.fit?.required ?? false,
                    default: options?.props?.fit?.default ?? undefined,
                },
                densities: {
                    type: String as PropType<CoreImgProps<T>['densities']>,
                    required: options?.props?.densities?.required ?? false,
                    default: options?.props?.densities?.default ?? undefined,
                },
                loading: {
                    type: String as PropType<CoreImgProps<T>['loading']>,
                    required: options?.props?.loading?.required ?? false,
                    default: options?.props?.loading?.default ?? undefined,
                },
                placeholder: {
                    type: Array as PropType<CoreImgProps<T>['placeholder']>,
                    required: options?.props?.placeholder?.required ?? false,
                    default: options?.props?.placeholder?.default ?? undefined,
                },
            },
            slots: Object as SlotsType<CoreImgSlots<T>>,
            emits: {},
        }
    )
}

export default defineComponentCoreImg({
    props: {
        fallbackSrc: {
            default: '/images/fallback.svg',
        },
    },
})

</script>

<style lang="scss" scoped>

</style>
